.footerLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.imgBox{
    width: 56px;
    height: 56px;
}
.imgBox img{
    width: 100%;
    height: 100%;
}
.songDetails{
    margin-left: 16px;
    margin-right: 16px;
}
.songDetails p{
    margin: 0px;
}
.songDetails p small{
    color: rgb(179, 179, 179);
    font-size: 11px;
}
@media screen and (max-width: 640px) {
    .imgBox{
        width: 42px;
        height: 42px;
    }
    .footerLeft button:nth-child(3){
        margin-left: auto;
    }
}