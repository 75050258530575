.button{
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    padding: 5px 0px;
    background-color: transparent;
}
.button img{
    width: 32px;
    height: 32px;
    margin-right: 16px;
}
.button p{
    margin: 0;
    text-align: left;
}