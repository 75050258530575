.SideNavbar{
    /*width: var(--sidenav-width);*/
    background-color: var(--sidenav-black);
    position: relative;
}
.Fixed{
    position: sticky;
    top: 0;
    bottom: 0;
    padding-top: 24px;
}
.changeWidth{
    width: 8px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 95px;
    cursor: col-resize;
    z-index: 9999;
}
.ActiveChange{
    border-right: 1px solid gray;
}
.ActiveChange::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}