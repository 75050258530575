.musicControl{
    width: 224px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.musicControl div button svg{
    fill: black !important;
}
.button{
    border: 0;
    background: transparent;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button svg{
    font-size: 16px;
    fill: hsla(0,0%,100%,.7);
    color: hsla(0,0%,100%,.7);
}
.musicControl svg:hover{
    fill: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
}


.nfLoader {
    content: "";
    width: 50px;
    height: 50px;
    background-image: url(./download.png);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    position: absolute;
    margin: -6px;
    width: inherit;
    height: inherit;
    animation: nfLoader-spin 1.1s linear infinite,1!important;
    -webkit-animation: nfLoader-spin 1.1s linear infinite,1!important; 
  }


  @keyframes nfLoader-spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes nfLoader-spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }