.layout{
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
}
@media screen and (max-width: 640px) {
    .layout{
        grid-template-columns: 1fr;
    }
}

.SearchPage{
    background-color: #121212;
    color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
}
.Search{
    padding: 0px 32px;
    padding-bottom: 32px;
    margin-bottom: 95px;
}
.SearchCardGrid{
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
    padding-top: 16px;
}
@media screen and (max-width: 640px) {
    .Search{
        padding: 16px;
        margin-bottom: 180px;
        margin-top: 24px;
    }
    .SearchCardGrid{
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;
    }
}