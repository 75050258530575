:root{
    --sidenav-black: #000;
    --sidenav-active: #282828;
    --text-gray: #b3b3b3;
    --text-white: #fff;
    --main-background: #121212;
    --card-background: #181818;
    --sidenav-width: 238px;
    --sidenav-margin-left: 24px;
    --sidenav-margin-right: 24px;
    --small-cards-column-width: 270px;
    --cards-column-width: 180px;
    --hover-home-bg: rgb(83, 83, 83);
}