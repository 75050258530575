.SeachBox{
    position: relative;
    max-width: 368px;
}
.SeachBox svg{
    position: absolute;
    color: black;
    top: 8px;
    left: 12px;
}
.SeachBox input{
    color: #000;
    padding: 6px 48px;
    height: 28px;
    width: 100%;
    border: 0;
    border-radius: 500px;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}
@media screen and (max-width: 640px) {
    .SeachBox svg{
        left: 26px;
        top: 15px;
    }
    .SeachBox input{
        padding: 6px 42px;
        height: 24px;
        width: 160px;
    }
}