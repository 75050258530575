@import "./variables.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}
a{
  text-decoration: none;
  cursor: pointer;
}
button{
  cursor: pointer;
}
:focus {
  outline: none;
}
svg{
  font-size: 24px;
}
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: hsla(0,0%,100%,.3);
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: hsla(0,0%,100%,.5);
}
::-webkit-scrollbar-thumb:active {
  background: hsla(0,0%,100%,.5);
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
::-moz-selection { color: inherit;  background: transparent; }
::selection      { color: inherit;  background: transparent; } 
nav .activeLink button{
  background-color: var(--sidenav-active);
}
nav .activeLink button p,
nav .activeLink button svg{
  color: var(--text-white);
}
p{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: unset;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.activeIcon svg{
  fill: #1db954 !important;
  color: #1db954 !important;
}
.PrevBtn,
.NextBtn{
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.7);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;
    margin-right: 16px;
}
.PrevBtn svg,
.NextBtn svg{
    font-size: 22px;
}
.activeTrack{
  background-color: hsla(0,0%,100%,.2) !important;
}
.activeTrack span p:first-child{
  color: #1db954 !important;
}
.activeTabBtn{
    background-color: #333;
}
@media screen and (max-width: 640px) {
  nav .activeLink button{
    background-color: inherit;
  }
  ::-webkit-scrollbar {
    width: 2px;
    height: 1px;
  }
}